import React, { useState, useRef, useEffect } from 'react';
import './App.css';
import axios from 'axios';
import _ from 'lodash';
import socketIOClient from "socket.io-client";
const BASEURL = "https://e.sandeepan.in"
// const ENDPOINT = "http://122.168.125.226:9003";
// const ENDPOINT = "http://app.sandeepan.in:9003";
const ENDPOINT = `${BASEURL}:9003`;


function LectureView(value) {
  return (
    <div className="imgcontainer">
      <img src={value && value.value == null ? "images/lecture.jpg" : value.value.lecture_img} alt="Avatar" className="lectureImg" />

      <div className="qut-btm-buttom">
        <div style={{ marginBottom: 50, marginTop: 12 }}>
          {value && value.value == null ? null :
            <h3 style={{ fontWeight: 'bold' }}> {value.value.lecture_name}</h3>
          }
        </div>
        <div className="animated">
          <h4 style={{ fontWeight: 'initial' }}>Your opinion matters,</h4>
          <h4 style={{ fontWeight: 'initial' }}>question will appear soon.</h4>
        </div>
        {/* <div id="progress"></div> */}
      </div>
    </div>
  )
}
function App() {
  const userDetail = localStorage.getItem('userDetails')
  const lastDetail = localStorage.getItem('lastDetail')

  // const audio = new Audio('music/Ambiphonic-lounge-easy-listening-music.mp3')
  const [username, setUsername] = useState(lastDetail ? JSON.parse(lastDetail).username : 'veer')
  const [password, setPassword] = useState(lastDetail ? JSON.parse(lastDetail).password : '1234567895')
  const [lectureOtp, setLectureOtp] = useState(lastDetail ? JSON.parse(lastDetail).lectureOtp : 2021)
  const [userData, setUserData] = useState(userDetail ? JSON.parse(userDetail) : null)
  const [response, setResponse] = useState("");
  const [currentCount, setCount] = useState(0);
  const [questionsSbmt, setQuestionsSbmt] = useState(false);
  const [showRight, setShowRight] = useState(false);
  const [answer, setAnswer] = useState('');
  const [notAnswer, setNotAnswer] = useState('');
  const [validation, setValidation] = useState(false);
  const [isConnect, setIsConnect] = useState(false);
  const [answerIsnull, setAnswerIsnull] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [flag, setFlag] = useState(true);
  const [msg, setMsg] = useState('');
  const [errMsg, setErrMsg] = useState(false);
  const timer = () => setCount(currentCount - 1);
  const socket = socketIOClient.connect(ENDPOINT, { jsonp: true }, { transports: ['websocket'] })

 
  const stateRef = useRef();
  stateRef.current = userData;
  useEffect(() => {

    socket.on('connect', function () {
      setIsConnect(false)
      console.log('socket  connect');
    });
    socket.on("sendtoMobileClient", async data => {
      if (JSON.parse(data).lecture_id) {
        // console.log('JSON.parse(data): ', JSON.parse(data));
        if (JSON.parse(data).user_data && stateRef.current !== '' && stateRef.current !== null) {
          const userId = stateRef.current.user_id;
          // console.log('userId: ', userId);
          let quesData ;
          const dataArry = JSON.parse(data).user_data ? JSON.parse(data).user_data :[];
          // if (flag) {
          //   console.log('if flag: ', flag);
          //   quesData = await dataArry.filter(value => parseInt(value.user_id) === userId);
          //   setFlag(false)
          // } else {
            // console.log('else flag: ', dataArry);
            quesData = await _.filter(dataArry, { user_id: userId.toString() })
          // }
          // console.log('quesData: ', quesData);
          if (JSON.parse(data).lecture_id === stateRef.current.lecture_id) {
            if (quesData.length) {
              setShowRight(false)
              setQuestionsSbmt(false)
              setAnswer('')
              setResponse(JSON.parse(data))
              setCount(JSON.parse(data).total_timer)
              playAudio()
            }
            // else {
            //   userQuestion(data, userId)
            // }
          }
        }
      }
    });
    socket.on('disconnect', function () {
      setIsConnect(true)
      console.log('socket  disconnect');
    });

  }, [userData]);

  useEffect(() => {
    socket.on('showResult', function (val) {
      setShowRight(true)
      // setResponse('')
      console.log('showResult', val);
      responseNull()
    });

  }, []);
  function responseNull() {
    setTimeout(function () {
      setResponse("")
    }, 5000)
  }
  function notAnswers() {
    if (stateRef.current !== '' && stateRef.current !== null) {
      if (questionsSbmt === false) {
        var val = {
          question_id: response.question_id,
          userid: stateRef.current.user_id,
          usertime: currentCount,
          userAns: '',
          deviceid: 'fgdfgd555',
          isanswered: 0,
          isnew: response.isnew,
          lec_type: response.lec_type,
          lecture_id: response.lecture_id,
        }
        socket.emit('sendtoserver', val);
      }
    }
  }

  useEffect(() => {
    if (currentCount === 0) {

      if (showRight === false) {
        if (questionsSbmt === false) {
          notAnswers()
        }
        // setResponse('')
        // setShowRight(true)
      }
      pauseAudio()
    }
    if (currentCount <= 0) {
      return;
    }
    const id = setInterval(timer, 1000);
    return () => clearInterval(id);
  }, [currentCount]);

  async function login() {
    if (username !== '' && password !== '' && lectureOtp !== '') {
      setValidation(false);
      try {
        const deviceId = 'dfgdfgd555';
        const deviceToken = 'dsfsdfsfsf';
        const baseUrl = 'https://e.sandeepan.in';
       
       const data = await axios.post(`${baseUrl}/admin/webservice/webservice.php?method=new_login&username=`+ username + `&password=` + password + `&device= `+deviceId+`&device_token=`+deviceToken+`&lecture_otp=`+ lectureOtp, {  'Access-Control-Allow-Origin': '*',  "Content-Type": "multipart/form-data" });


        //  const payload = {
        //   method: 'new_login',
        //   username: username,
        //   password: password,
        //   device: deviceId,
        //   device_token: deviceToken,
        //   lecture_otp: lectureOtp
        // }

      // axios.post(`${baseUrl}/admin/webservice/webservice.php`, payload, {  'Access-Control-Allow-Origin': '*',  "Content-Type": "multipart/form-data" })
      // .then((res) => {
      //   if (res.status === 200) {
      //     //all cookies are set in you're browser
      //     console.log(res);
      //   }
      // })
      // .catch((err) => {
      //   console.log(err);
      // });


      
      //   const data = await axios({
      //     method: 'post',
      //     url:  baseUrl,
      //     data: payload, // you are sending body instead
      //     headers: {
      //     'Access-Control-Allow-Origin': '*',
      //     'Content-Type': 'application/json'
      //     }, 
      //   })


      // console.log('data', data);
        // const data = await axios.post(`${BASEURL}/admin/webservice/webservice.php?method=new_login&username=`+ username + `&password=` + password + `&device= `+deviceId+`&device_token=`+deviceToken+`&lecture_otp=`+ lectureOtp);
        
        // const data ={ data : {"success":"1","msg":"Registered successfully","user_id":"2250","lecture_id":"138","questions_count":"2","lecture_name":"Test 3-May","lecture_img":"http:\/\/e.sandeepan.in\/admin\/upload\/lecture_img\/1651557999.jpg"}}


        // if (data.data.success == 1) {
        //   setErrMsg(false)
        //   var val = { username: username, lectureOtp: lectureOtp, password: password };
        //   localStorage.setItem('userToken', '01')
        //   localStorage.setItem('userDetails', JSON.stringify(data.data))
        //   localStorage.setItem('lastDetail', JSON.stringify(val))
        //   setUserData(data.data)
        // } else {
        //   setMsg(data.data.msg)
        //   setErrMsg(true)
        // }
      } catch (error) {
        console.log('error: ', error);
      }
    } else {
      setValidation(true);
      //alert("Please enter username and password !")
    }
  }




  async function playAudio() {
    try {
      var x = document.getElementById("myAudio");
      await x.play();
    } catch (err) {
      console.log('err: ', err);
    }
  }

  async function pauseAudio() {
    try {
      var x = document.getElementById("myAudio");
      await x.pause();
    } catch (err) {
      console.log('err: ', err);
    }
  }



  async function logout() {
    setConfirm(false)
    try {
      localStorage.removeItem('userToken');
      localStorage.removeItem('userDetails');
      const data = await axios.post(`${BASEURL}/admin/webservice/webservice.php?
        method=logout&user_id=`+ stateRef.current.user_id)
      // const data = await axios.post(`http://app.sandeepan.in/webservice/webservice.php?
      //  method=logout&user_id=`+ stateRef.current.user_id)
      localStorage.removeItem('userToken');
      localStorage.removeItem('userDetails');
      setUserData('')
      window.location.reload();
    } catch (error) {
      console.log('error: ', error);
    }
  }


  function reconnect() {
    socket.emit("reconnect");
    setIsConnect(false)
  }
  function onSubmit() {
    if (answer !== '') {
      setAnswerIsnull(false)
      var val = {
        question_id: response.question_id,
        userid: stateRef.current.user_id,
        usertime: currentCount,
        userAns: answer,
        deviceid: 'fgdfgd555',
        isanswered: 1,
        isnew: response.isnew,
        lec_type: response.lec_type,
        lecture_id: response.lecture_id,
      }
      socket.emit('sendtoserver', val);
      setQuestionsSbmt(true)
      pauseAudio()
      setNotAnswer(answer)
    } else {
      setAnswerIsnull(true)
    }

  }

  const ques = response.question ? response.question.replace(/(<([^>]+)>)/ig, "") : ''
  const options = response.options ? response.options.split(',') : []
  const sequence = response.sequence ? response.sequence.split(',') : []
  const colors = ['#0088FE', '#A52A2A', '#FFBB28', '#FF8042', '#D2691E'];
  return (
    <div className="mainDiv">
      {localStorage.getItem('userToken') ?
        <div className="main-qut-form">
          {response ? null :
            <div>
              <a onClick={() => setConfirm(true)} className="logoutIcon" href="#">
                <img src="images/logout-icone.png" style={{ width: 16 }} />
              </a>
            </div>
          }
          {currentCount == 0 || questionsSbmt == true ? null :
            <div className="loaderCenter">
              <div className="loader" />
              <span className="base-timer__label">{currentCount}</span>
            </div>
          }
          {response ? response.user_data ?
            // questionsSbmt ? <LectureView value={stateRef.current} /> :
            <form>
              <div className="scp-quizzes-main">
                <div className="scp-quizzes-data">
                  <h4><b>Q.</b> {ques}</h4>
                </div>

              </div>
              {response.option_status == 0 ?
                currentCount == 0 ?
                  <div className="qut-btm-buttom">
                    <div style={{ width: '100%' }}>
                      <div className="">
                        {sequence.map((value, i) => {
                          return (
                            <div key={i} className="option">
                              <span onClick={() => setAnswer(value)}
                                className={answer === value ? "active-option" : ""}
                                style={{ backgroundColor: answer === value ? "#00a4bf" : showRight && response.right_ans === value ? 'green' : colors[i] }}
                              // style={{ backgroundColor: answer === colors[i] ? showRight && response.right_ans === colors[i] ? 'green' : "#00a4bf" : showRight && response.right_ans === colors[i] ? 'green' : '' }}
                              >{value}
                              </span>
                            </div>
                          )
                        })
                        }
                      </div>
                    </div>
                  </div> : null :
                <div className="">
                  <div style={{ width: '100%' }}>
                    <div className="">
                      {options.map((value, i) => {
                        return (
                          <div className="option-que">
                            <span
                              style={{
                                backgroundColor: answer === sequence[i] ? showRight && response.right_ans === sequence[i] ? 'green' : "#00a4bf" : showRight && response.right_ans === sequence[i] ? 'green' : '',
                                //backgroundColor: showRight && response.right_ans === value ? 'green' : '#ffffff' 
                              }}
                            >
                              <b>{sequence[i]}) </b>
                              {' '}{value.replace(/(<([^>]+)>)/ig, "")}
                            </span>
                          </div>
                        )
                      })
                      }
                    </div>
                  </div>

                </div>
              }
              {
                currentCount == 0 || questionsSbmt == true ? null :
                  <div className="qut-btm-buttom">
                    <div style={{ width: '100%' }}>
                      <div className="">
                        {sequence.map((value, i) => {
                          return (
                            <div key={i} className="option">
                              <span onClick={() => setAnswer(value)}
                                className={answer === value ? "active-option" : ""}
                                style={{ backgroundColor: answer === value ? "#00a4bf" : showRight && response.right_ans === value ? 'green' : colors[i] }}
                              >{value}
                              </span>
                            </div>
                          )
                        })
                        }
                      </div>
                    </div>

                    {currentCount == 0 ? null :
                      <button
                        type="button"
                        style={{ height: '9vh', fontWeight: 'bold', fontSize: 20 }}
                        className="qut_sub_btn"
                        onClick={() => onSubmit()}
                        disabled={currentCount === 0 ? true : false}>Submit</button>}
                  </div>
                // : null
              }
            </form>
            :
            <LectureView value={stateRef.current} />
            :
            <LectureView value={stateRef.current} />
          }
          {/* <button onClick={() => localStorage.clear()}>Logout</button> */}
        </div>
        :
        <div style={{ margin: 0, bottom: 0 }}>
          <form>
            <div className="imgcontainer">
              <img src="images/Totall.png" alt="Avatar" className="avatarLogin" />
            </div>
            <div className="container">
              {/*<label htmlFor="uname">
                <b>Username</b>
              </label>*/}
              <input
                type="tel"
                placeholder="Id"
                value={lectureOtp}
                onChange={(event) => setLectureOtp(event.target.value)}
                required />
              <input
                type="text"
                placeholder="Name"
                value={username}
                onChange={(event) => setUsername(event.target.value)}
                required />
              {/*<label htmlFor="psw">
                <b>Mobile</b>
              </label>*/}
              <input
                type="tel"
                pattern="\d{3}[\-]\d{3}[\-]\d{4}"
                title="Phone number with 7-9 and remaing 9 digit with 0-9"
                placeholder="Mobile"
                value={password}
                onChange={(event) => setPassword(event.target.value)}
                required />
              {validation &&
                <span style={{ fontSize: 15, color: 'red' }}>
                  Please enter id,name and mobile no
                </span>}
              <button type="button" onClick={() => login()}>Login</button>
            </div>
          </form>
        </div>
      }
      {
        isConnect &&
        <div id="id01" className="modal">
          <div className="modal-content">
            <span style={{ fontSize: 15 }}>
              You have disconnected, Please contact administrator!!!
        </span>
            {/*<div className="bottomBtn">
        <a href="#" onClick={()=> setIsConnect(false)} className="cancelbtna">Cancel</a>
        <a href="#" onClick={()=>reconnect()} className="Okbtn">Join</a>
      </div>*/}
          </div>
        </div>
      }
      {
        errMsg &&
        <div id="id01" className="modal">
          <div className="modal-content">
            <span style={{ fontSize: 15 }}>
              {msg}
            </span>
            <div className="bottomBtn">
              {/* <a href="#" onClick={() => setIsConnect(false)} className="cancelbtna">Cancel</a> */}
              <a href="#" onClick={() => setErrMsg(false)} className="Okbtn">OK</a>
            </div>
          </div>
        </div>
      }
      {
        confirm &&
        <div id="id01" className="modal">
          <div className="modal-content">
            <span style={{ fontSize: 15 }}>
              Are you sure you want to logout
        </span>
            <div className="bottomBtn">
              <a href="#" onClick={() => setConfirm(false)} className="cancelbtna">No</a>
              <a href="#" onClick={() => logout()} className="Okbtn">Yes</a>
            </div>
          </div>
        </div>
      }
      {
        answerIsnull &&
        <div id="id01" className="modal">
          <div className="modal-content">
            <span style={{ fontSize: 15 }}>
              Please select an option
        </span>
            <div className="bottomBtn">

              <a href="#" onClick={() => setAnswerIsnull(false)} className="Okbtn">OK</a>
            </div>
          </div>
        </div>
      }
      <audio style={{ display: 'none' }} preload="auto" id="myAudio" src="music/Ambiphonic-lounge-easy-listening-music.mp3" controls autoPlay />
    </div >
  );
}

export default App;
